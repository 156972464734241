.btn {
    border-radius: 4px;
    transition: all .15s ease-in;
    outline: none;
    font-family: 'open sans', sans-serif;
    font-weight: 500;
  }
  
  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  ul.no-bullets {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margins */
    text-align: left;
  }

  th{
    font-weight: normal;
    padding-right: 70px;
    padding-left: 5px;
}
  